<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    breakpoint="sm"
    size="sm"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand to="/">
      <p class="text-center mb-1">
        <CImg src="/img/smart-pol-logo-v1.png" :width="($store.state.sidebarMinimize)?'70%':'50%'"/> <br>
        <CBadge class="mt-1 mr-1 mb-0" color="primary">ATG</CBadge>
        <CBadge v-show="!$store.state.sidebarMinimize" class="mt-1 mb-0" color="info">Manager</CBadge>
      </p>
      <!--
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 642 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
      -->
    </CSidebarBrand>
    <CRenderFunction :flat="true" class="small" :contentToRender="[navMenu]"/>
<!--    <CRenderFunction flat :contentToRender="menuItems"/>-->
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import {apiCall} from '@/common/utils';

export default {
  name: 'TheSidebar',
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },
  data(){
    return {
      navMenu: {
        _name: 'CSidebarNav',
        _children: [],
      },

      menuItems: [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: 'Dashboard',
              to: '/dashboard',
              icon: 'cil-speedometer',
              /*badge: {
                color: 'primary',
                text: 'NEW'
              }*/
            },
            {
              _name: 'CSidebarNavTitle',
              _children: ['Monitoring']
            },
            {
              _name: 'CSidebarNavDropdown',
              name: '모니터링',
              to: '/monitor/tank',
              icon: 'cil-airplay',
              items: [
                {
                  name: '탱크모니터',
                  to: '/monitor/tank',
                  icon: 'cil-center-focus'
                },
                {
                  name: '실시간패킷',
                  to: '/monitor/real-time',
                  icon: 'cil-sync'
                }
              ]
            },

            {
              _name: 'CSidebarNavTitle',
              _children: ['Inventory']
            },
            {
              _name: 'CSidebarNavDropdown',
              name: '재고조회',
              to: '/inventory/tank',
              icon: 'cil-drop',
              items: [
                {
                  name: '탱크별재고',
                  to: '/inventory/tank',
                  icon: 'cil-beaker'
                },
                {
                  name: '입출고현황',
                  to: '/inventory/inout',
                  icon: 'cil-transfer'
                },
              ]
            },
            {
              _name: 'CSidebarNavTitle',
              _children: ['Settings']
            },
            {
              _name: 'CSidebarNavDropdown',
              name: '설정',
              route: '/setting/site',
              icon: 'cil-settings',
              items: [
                {
                  name: '사이트 설정',
                  to: '/setting/site',
                  icon: 'cil-building'
                },
                {
                  name: '유종 설정',
                  to: '/setting/oil',
                  icon: 'cil-drop'
                },
                {
                  name: '장비 설정',
                  to: '/setting/machine',
                  icon: 'cil-router'
                },
                {
                  name: '탱크 설정',
                  to: '/setting/tank',
                  icon: 'cil-inbox'
                },
                {
                  name: '탱크 테이블',
                  to: '/setting/tank-table',
                  icon: 'cil-layers'
                },
                {
                  name: '경보알람',
                  to: '/setting/alarms',
                  icon: 'cil-bell-exclamation'
                },
                {
                  name: '시스템서비스',
                  to: '/setting/services',
                  icon: 'cil-applications'
                },
                {
                  name: '사용자관리',
                  to: '/setting/users',
                  icon: 'cil-people'
                },
                { name: '접근 기록', to: '/setting/access-log'},
                { name: '접근 설정', to: '/setting/access-map'},
                { name: '내 정보', to: '/setting/user-info'},
              ]
            }
          ]
        }
      ]
    }
  },
  async created(){
    console.log( "[TheSideBar]---------> created ----");
    try{
      this.user = this.$store.state.user;

      const rs = await apiCall('GET', '/api/access/menu');
      if( rs.code===200 ){
        this.navMenu._children = rs.result;
      }else{
        console.log(rs);
      }

    }catch(err){
      console.error(err);
    }

  },
}
</script>

