<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      src="img/brand/iotels-logo.png"
      width="190"
      height="46"
      alt="Iotels Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav>
      <BBadge variant="danger"
              class="font-weight-bold font-lg mr-2"
              :class="{blink:!$store.state.serverConnected}"
              v-if="!$store.state.serverConnected">ATG 서버 연결 유실
      </BBadge>
    </CHeaderNav>

   <CHeaderNav v-if="liveness" class="d-md-down-none mr-auto">
     <BBadge class="mr-1" v-for="(v,k) in livenessNameMap" :key="v" :variant="liveness[k]?'success':'dark'">{{ v }}</BBadge>
   </CHeaderNav>

    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">


          <BButton :variant="$store.state.soundOn?'outline-info':'secondary'" class="mr-1"
                   @click="() => $store.commit('toggle', 'soundOn')"
          >
            <CIcon v-if="$store.state.soundOn" name="cil-volume-high"/>
            <CIcon v-else name="cil-volume-off"/>
            <span class="small ml-2"><b>SOUND {{$store.state.soundOn?'ON':'OFF'}}</b></span>
          </BButton>

          <BButton variant="outline-info" @click="()=> $store.state.largeFont=true"  v-show="!$store.state.largeFont">
            <BIconPlusCircle/>
          </BButton>

          <BButton variant="outline-info" @click="()=> $store.state.largeFont=false"  v-show="$store.state.largeFont">
            <BIconDashCircleFill/>
          </BButton>



      </CHeaderNavItem>


      <CHeaderNavItem>

      </CHeaderNavItem>


      <TheHeaderDropdownAccnt :key="accountKey"/>

      <BNavItemDropdown size="sm" id="user-nav" :text="$store.state.user.nick" class="mr-3">
        <BDropdownItem @click="clickUserInfo">정보 변경</BDropdownItem>
        <BDropdownDivider/>
        <BDropdownItem @click="clickLogout">로그아웃</BDropdownItem>
      </BNavItemDropdown>


      <CHeaderNavItem class="px-3 mr-2">
        <button
          class="c-header-nav-btn"
          :class="{ bellBlink: isBellBlink }"
          @click="$store.commit('toggle', 'asideShow')"
        >
          <CIcon size="xl" class="mr-2" name="cil-bell"/>
          <BBadge variant="danger">
            {{ $store.state.alarm.count.total }}
          </BBadge>
        </button>
      </CHeaderNavItem>

    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>

  </CHeader>
</template>


<style>
.tooltip .tooltip-inner {
  text-align: left;
  max-width: 350px !important;
  width: 250px !important;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink-effect 1s step-end infinite;
}
.bellBlink {
  animation: blink-effect 0.3s;
  animation-iteration-count: 4;
}

</style>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import {apiCall, beepSound, clone, sleep} from "@/common/utils";


export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data(){
    return {
      user: this.$store.state.user,
      accountKey: Math.floor((Math.random() * 100000)),
      timer: null,
      btnDisabled: true,
      isBellBlink: false,

      socket: null,
      liveness: null,
      beforeLive: null,
      livenessNameMap: {},
      serverLiveInterval: null,
    }

  },
  computed: {
    alarmCounter : function(){
      return this.$store.state.alarm.count.total;
    }
  },

  async created(){
    console.log( "-------------------------- TheHeader created ------------------------ ");

    // this.isErsSound = $isErsOn;
    // this.isMsgSound = $isMsgOn;
    await sleep(3000);
    this.socket = this.$store.state.socket;
    if(this.socket) {
      this.socket.removeListener('LIVENESS', this.livenessHandler);
      this.socket.on('LIVENESS', this.livenessHandler);
    }
    await this.getLiveness();

    this.serverLiveInterval = setInterval( this.serverLiveCheck,  1000 * 300 ); // 분

    // this.user = $user;
    this.accountKey = Math.floor((Math.random() * 100000));


  },

  methods: {
    clickLogout : async function(){
      await this.$store.dispatch('LOGOUT', 'U100');
      // setTimeout( async ()=>await this.$router.push({ path: '/login'  }), 500 );
    },

    clickUserInfo: function() {
      this.$router.push({ path: '/users/user-info'  });
    },

    async soundToggle(){
      // console.log('soundToggle', this.$store.state.soundOn);
      await beepSound('success');
    },

    livenessHandler(data){
      this.beforLive = clone(this.liveness);
      this.liveness = data;
    },

    async getLiveness(){
      try {
        const r = await apiCall('get', `/api/job/liveness`);
        // console.log('[TheHeader]_______________________ getLiveness __________________', r);
        if (r.code === 200){
          this.liveness = r.result.liveness;
          this.livenessNameMap = r.result.livenessName;
        }
      }catch(err){
        console.error(err);
      }
    },

    async serverLiveCheck(){
      try {
        const rs = await apiCall('GET','/api/server-liveness' );
        this.$store.state.serverLiveness = rs.result.serverLiveness;
      }catch(err){
        console.error(err);
        this.$store.state.serverLiveness = false;
      }
    }
  },
  watch: {
    alarmCounter() {
      this.isBellBlink = !this.isBellBlink;
    }
  },
  beforeDestroy(){
    if(this.spinnerInterval) clearInterval( this.spinnerInterval);
    if(this.serverLiveInterval) clearInterval( this.serverLiveInterval);

    this.socket.removeListener('LIVENESS', this.livenessHandler);

  }

}
</script>
