<template>
  <CSidebar
    aside
    :show="$store.state.asideShow"
    @update:show="(val) => $store.commit('set', ['asideShow', val])"
    unfoldable
    breakpoint="sm"
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'asideShow')"/>
    <CTabs tabs class="nav-underline nav-underline-info">
      <CTab active>
        <template slot="title">
          <CIcon name="cilBellExclamation" class="mr-1"/> <b>경보</b>
        </template>

        <div v-if="alerts.length!==0">
          <BButton variant="outline-danger"
                   size="sm"
                   @click="()=>{alerts=[]}"
                   class="mt-2">
            <BIconTrash2Fill/> <B>경보 전체 삭제</B>
          </BButton>
        </div>
        <b-list-group class="mt-2 v-scroll">
          <b-list-group-item v-for="(e,idx) in alerts" :key="idx"
                             class="flex-column align-items-start border-warning mb-1"
                             >
            <div class="d-flex w-100 justify-content-between">
              <BBadge class="font-sm" style="min-width:64px"
                      :variant="eventColor[e.code]">
                {{ e.name }}
              </BBadge>

              <BBadge class="font-sm" :class="{ blink: getDiffSeconds(e.dt) < 60 }">
                {{e.tnm}}
              </BBadge>

              <i class="small font-sm">{{ toShortTime(e.dt) }}</i>
              <BBadge variant="info" class="btn"
                      @click="delayAlert(e,idx)">
                <BIconCheck/>
              </BBadge>
              <BBadge variant="dark" class="btn"
                      @click="removeAlert(idx)">
                <BIconTrash/>
              </BBadge>
            </div>

            <BRow class="mt-1 small text-wrap">
              <BCol>
                {{ e.text?.split('|').slice(1).toString() }}
              </BCol>

            </BRow>
          </b-list-group-item>
        </b-list-group>


<!--        <BTable small selectable hover responsive bordered dark
                class="text-nowrap small p-0"
                thead-class="text-center small"
                ref="accessTable"
                id="accessTable"
                select-mode="single"
                selectedVariant="success"
                :items="alerts"
                :fields="alertFields"
                @row-selected="alertSelected">
        </BTable>                                       -->
      </CTab>


      <CTab >
        <template slot="title">
          <CIcon name="cil-cog" class="mr-1"/> <b>설정</b>
        </template>

        <CListGroup class="list-group-accent">
          <CListGroupItem
            class="list-group-item-accent-secondary bg-light text-center
            font-weight-bold text-muted text-uppercase small"
          >
            <BInputGroup size="sm" prepend="경보">
              <BFormSelect size="sm"
                           class="mr-2"
                           v-model="fromDays"
                           style="background-color:#2f303a"
                           @input="selectDayOpts"
                           :options="dayFromOpts"
              />
              <BInputGroupAppend>
                <BButton size="sm" variant="danger" style="min-width:80px" pill>
                  <strong>{{comma($store.state.alarm.count.total)}}</strong>
                </BButton>
              </BInputGroupAppend>
            </BInputGroup>
          </CListGroupItem>


          <CListGroupItem v-for="(evtName,key) in eventCodeMap" :key="key"
                          :href="`/#/monitor/events?code=${key}&from=${fromDays}`"
                          class="small p-1 m-0"
                          :class="`list-group-item-accent-${eventColor[key]}`"
          >
            <div class="float-left">
              <BBadge class="font-lg btn" style="min-width:120px" :variant="eventColor[key]" @click="getEventTankCount(key)">
                <strong>{{ evtName }}</strong>
              </BBadge>
            </div>
            <BSpinner variant="danger" class="ml-1 mt-2" small  v-show="evtReceived[key]"  type="grow" label="Spinning"/>
            <div class="float-right">
<!--              <BIconPlusCircleFill :id="`count_${key}`" v-show="false" variant="danger" />-->

              <BButton size="sm"
                       class="ml-1"
                       :variant="`outline-${eventColor[key]}`"
                       style="min-width:90px" pill>

                <strong>{{comma($store.state.alarm.count[key])}}</strong>
              </BButton>
            </div>
          </CListGroupItem>


          <CCard v-if="eventCodeMap" class="mt-1">
            <CCardBody class="p-0">
              <BRow>
                <BCol class="text-center">
                  <BButtonGroup size="sm" class="mb-2">
                    <BButton :variant="eventColor[evtCode]" pill>
                      <BIconBellSlashFill class="mr-1"/> {{ eventCodeMap[evtCode] }} | {{evtCode}} <b>알람 확인/지연 설정</b>
                    </BButton>
                  </BButtonGroup>
                </BCol>
              </BRow>


              <BRow v-for="(e,i) in eventTanks" :key="i">
              <BCol>
                <BInputGroup size="sm" class="small mb-1">
                  <BButtonGroup size="sm" class="mr-1">
                    <BButton variant="outline-info" class="font-sm small" style="min-width:70px">{{tankMap[e.tid]?.name}}</BButton>
                  </BButtonGroup>
                  <BFormSelect size="sm"
                               style="background-color:#2f303a"
                               v-model="suppressAlarmMin"
                               :options="suspendOpts"
                  />
                  <BInputGroupAppend>
                    <BButton size="sm" variant="outline-warning" @click="setSuppressAlarm(e.tid, evtCode, suppressAlarmMin)">
                      알람지연
                    </BButton>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>
            </BRow>

            <BRow>
              <BCol>
                <BInputGroup size="sm">
                  <BFormSelect size="sm"
                               v-model="suppress.tid"
                               style="background-color:#2f303a"
                               :options="tankOpts"/>
                  <BFormSelect size="sm"
                               v-model="suppress.min"
                               style="background-color:#2f303a"
                               :options="suspendOpts"/>
                  <BInputGroupAppend size="sm">
                    <BButton size="sm"
                             variant="outline-warning"
                             @click="setSuppressAlarm(suppress.tid, evtCode, suppress.min)">알람지연</BButton>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>
            </BRow>
            <BTableSimple class="small mt-1" style="min-height: 400px" small bordered sticky-header>
              <BThead class="text-center bg-gradient-dark">
                <BTh width="80">경보</BTh>
                <BTh>알람 지연</BTh>
              </BThead>
              <BTbody>
              <BTr v-for="(obj,k) in suppressAlarms" :key="k">
                <BTd class="text-center">
                  <BBadge class="font-sm" :variant="eventColor[k]">
                  {{ eventCodeMap[k].replace('경보','') }}
                  </BBadge>
                </BTd>
                <BTd>
                  <BRow v-for="(dt,tid) in obj" :key="tid">
                    <BCol cols="5"><BBadge class="font-sm" variant="light">{{ tankMap[tid]?.name }}</BBadge></BCol>
                    <BCol class="ml-1">
                      <BBadge class="font-sm" variant="dark">{{`~ ${toShortTime(dt)}`}}</BBadge>
                    </BCol>
                  </BRow>
                </BTd>

              </BTr>
              </BTBody>
            </BTableSimple>

            </CCardBody>
          </CCard>
        </CListGroup>
      </CTab>
    </CTabs>
  </CSidebar>

</template>

<script>
import moment from "moment";
import {
  apiCall, beepSound, speech, comma, sleep, getDiffSeconds, clone
} from '@/common/utils';
import qs from 'querystring';


export default {
  name: 'TheAside',
  data () {
    return {
      comma,
      isReady: false,
      suppress:{
        tid: '',
        evtCode: '',
        min: 10,
      },
      suppressAlarms: {
        '1':null,
        '2':null,
        '3':null,
        '4':null,
        '5':null,
        '6':null,
        '7':null,
        '8':null,
        '9':null,
      },

      evtReceived: {},
      evtLatest: {}, // {tid: {code, dt, toasted}}

      fromDays: 3,
      fromDts: null,
      toDts: null,
      dayFromOpts: [
        {value:3, text:'최근 3일'},
        {value:7, text:'최근 7일'},
        {value:14, text:'최근 2주'},
        {value:28, text:'최근 4주'},
        {value:90, text:'최근 3개월'},
      ],
      eventCodeMap: null,
      tankMap: null,
      tankOpts: null,
      eventColor: {
        '1':'warning', // 넘침
        '2':'primary', // 부족
        '3':'danger',  // 화재
        '4':'info',    // 수분
        '5':'success',  // 누유
        '6':'danger',  // 넘침2
        '7':'dark',    // 부족2
        '8':'light',   // 점검?
        '9':'secondary'  // 센서점검
      },
      alarmCfg: this.$store.state.alarm,
      isShowEventTank: false,
      evtCode: '1',
      eventTanks: [],
      suspendOpts: [
        {value:2, text:'2 분'},
        {value:5, text:'5 분'},
        {value:10, text:'10 분'},
        {value:30, text:'30 분'},
        {value:60, text:'1 시간'},
        {value:180, text:'3 시간'},
        {value:360, text:'6 시간'},
        {value:720, text:'12 시간'},
        {value:1440, text:'24 시간'},
        {value:2880, text:'2 일'},
        {value:4320, text:'3 일'},
        {value:10080, text:'7 일'},
      ],
      suppressInterval: null,
      suppressAlarmMin: 10,
      socket: this.$store.state.socket,
      alerts: [],
      alertFields: [
        { key:'tnm'  , sortable: true, label: '탱크'},
        { key:'dt'   , sortable: true, label: '일시', formatter: v=>{return (v? moment(v).format('MM/DD HH:mm'):'')} },
        { key:'name'  , sortable: true, label: '경보'},
        { key:'text' , sortable: true, label: '내용'},
      ],


    }
  },
  computed: {},
  created() {
    console.log( "\n\n\n\n[TheAside]-----------created !!! --------------" );

  },
  async mounted() {
    try{

      await sleep(1000);
      this.eventCodeMap = this.$store.state.codeMaps['EVENT'];
      console.debug("\n\n\neventCodeMap ----------------------------->", this.eventCodeMap);

      this.tankMap = this.$store.state.tanks['map'];
      this.tankOpts = this.$store.state.tanks['opts'];

      this.fromDays = this.$store.state.alarm.count.fromDays? this.$store.state.alarm.count.fromDays:3;

      this.setEvtLatestByTid();

      await this.getSuppressAlarms();

      this.suppressInterval = setInterval( async ()=>{ await this.getSuppressAlarms() }, 1000 * 60);
      this.fromDts =  moment().subtract(this.fromDays, 'days').toISOString();
      this.toDts = moment().toISOString();

      await this.getCount();
      if(!this.$store.state.soundOn){
        if( await this.confirmModal('브라우저 사운드 설정이 Off 입니다. 사운드 설정을 On 합니다.') ){
          this.$store.commit('toggle', 'soundOn');
        }
      }

      if( !this.socket ) await sleep(3000);
      this.socket = this.$store.state.socket;

      if(this.socket) {
        console.warn("\n\n\n\n[TheAside] ---------------- register eventAlarmHandler #########");
        this.socket.removeListener('event', this.eventAlarmHandler)
        this.socket.on('event', this.eventAlarmHandler); // { tid, tnm, cmd, dt, payload }

        console.warn( "\n\n\n\n[TheAside] ---------------- socket object ------> is connecting? ", this.socket.connected );
        this.isReady = true;
      }else{
        console.warn("\n\n\n\n[TheAside] ---------------- socket not found ---------------\n\n\n\n\n");
        this.isReady = false;
        alert("경보알람 수신이 불가 합니다. 로그아웃 후 재 로그인 바랍니다.\n지속적인 수신불가시 관리자 문의 바랍니다");
      }

    }catch(err){
      console.error(err);
    }


  },
  methods: {
    async delayAlert(e, idx){
      await this.setSuppressAlarm( e.tid, e.code, this.suppress.min );
      this.alerts.splice(idx,1);
    },
    removeAlert(e, idx){
      this.alerts.splice(idx,1);
    },

    getDiffSeconds,
    eventAlarmHandler(data){
      // console.log('[TheAside] eventAlarmHandler---data--->', data);
      // data = { tid, tnm=tankName, cmd, dt, payload }
      const {payload} = data;
      if(payload.length) {
        // speech(`경보 알람 수신`);
        payload.map( e => {
          // 다음 알람 시간정보 구성
          // console.debug( '[TheAside] eventAlarmHandler --- payload', e );
          this.$store.state.alarm.count[e.code]++;
          this.$store.state.alarm.count['total']++;

          this.evtReceived[e.code] = true;
          setTimeout(()=>{ this.evtReceived[e.code]=false }, 2000);

          const latest = this.evtLatest[e.code][e.tid];
          if( getDiffSeconds(latest?.dt) > 60 ) latest.toasted = false;
          // console.log( this.$refs[`btn_count_${e.code}`] );

          if( !this.isAlarmSuppressed(e.code, e.tid) ) {
            this.consoleAlarm(e);
          }

        })
      }
    },

    async selectDayOpts(){
      this.toDts = moment().format('YYYY-MM-DD');
      this.fromDts = moment().subtract(this.fromDays,'days').format('YYYY-MM-DD');
      await this.getCount();
    },

    setEvtLatestByTid(){
      // 탱크별 최종 이벤트 코드 저장
      for(const code in this.eventCodeMap){
        this.evtLatest[code] =  {} ;
        for(const tid in this.tankMap){
          this.evtLatest[code][tid] = { dt: null, toasted:false }
        }
      }
      // console.log(this.evtLatest);
    },

    async getCount() {
      let paramMap = {};
      try{
        this.$store.state.alarm.count.fromDays = this.fromDays;
        paramMap['fromDts'] = moment().subtract(this.fromDays, 'days').toISOString();
        if(this.toDts) paramMap['toDts'] = this.toDts;
        let query = qs.stringify( paramMap )
        const {result} = await apiCall('get', `/api/event/count?${query}`);
        // console.log(result);
        let totalCount = 0;
        let alarmCount = this.$store.state.alarm.count;

        Object.keys(alarmCount).map(k=>{ alarmCount[k] = 0;});

        result.map(r=>{
        alarmCount[r.code] = r.count;
          totalCount += r.count;
        })
        alarmCount.total = totalCount;

        speech(`최근 ${this.fromDays}일 동안, ${totalCount} 건의 경보 발생.`);

      }catch(err){
        console.error(err);
      }
    },

    async getEventTankCount(code){
      let paramMap = {};
      try{

        this.evtCode = code;
        paramMap['code'] = code;
        if(this.fromDts) paramMap['fromDts'] = this.fromDts;
        if(this.toDts) paramMap['toDts'] = this.toDts;

        let query = qs.stringify( paramMap )
        this.eventTanks = [];
        const {result} = await apiCall('get', `/api/event/tank-count?${query}`);
        if(result) {
          this.eventTanks = result;
        }
        // console.log('showEventTank result --->', result);

      }catch(err){
        console.error(err);
      }finally{
        this.isShowEventTank = true;
      }
    },
    /** 알람 확인/지연 설정 */
    async setSuppressAlarm(tid, evtCode, min=10){
      try{
        if(!tid) {
          this.toastWarn('탱크를 선택 하세요');
          return;
        }

        const suppress = clone(this.suppress);

        // console.log( 'setSuppressAlarm --- tid --->', tid)
        suppress.tid = tid;
        suppress.min = min?min:10;
        suppress.evtCode = evtCode;

        const r = await apiCall('put', `/api/alarm/suppress`, suppress );
        if(r.code===200) {
          await this.getSuppressAlarms();
          const msg = '탱크 '+this.tankMap[tid]?.name + ' ' + this.eventCodeMap[evtCode] + ', ' + min + "분 지연설정 완료"
          await this.toastInfo(msg, '경보 지연설정');
        }else{
          await this.toastResult(r);
        }

      }catch(err){
        console.error(err);
      }
    },

    async getSuppressAlarms(){
      try{
        const r = await apiCall('get', `/api/alarm/suppress`, this.suppress);

        Object.keys(r.result).map(evtCode=>{
          this.suppressAlarms[evtCode] = r.result[evtCode];
        })
        // this.suppressAlarms = r.result;
        // console.log( 'getSuppressAlarms result ---->', r.result );
        // console.log( 'getSuppressAlarms suppressAlarms ---->', this.suppressAlarms );

      }catch(err){
        console.error(err);
      }
    },

    async consoleAlarm(e){
      let cfg = this.alarmCfg.config;
      let tankCfg = this.alarmCfg.tank;

      try{
        if(!cfg.enabled) {
          // console.log('alarm disabled')
          return;
        }

        let tankAlarm = tankCfg[e.tid];

        if(!tankAlarm.enabled) {
          // console.log('tankAlarm disabled')
          return;
        }

        let isAlarm = cfg.events.find(evtCfg=>{
          return (evtCfg.code===e.code)? evtCfg.enabled:false
        });

        let isTankAlarm = tankAlarm.events.find(te=>{
          return (te.code===e.code)? te.enabled:false;
        });

        if(isAlarm){
          // TTS 출력
          // console.log('consoleAlarm e --->', e);

          if( isTankAlarm && cfg.console.tts && tankAlarm.console.tts) {
            speech(`${e.name}!`);
          }

          // 토스트 알림
          if(isTankAlarm && cfg.console.icon && tankAlarm.console.icon) {
            // console.log('[TheAside] event toast --->' , e);
            const last = this.evtLatest[e.code][e.tid];

            if( !last.toasted ){
              // this.toastEvent(`[${e.name}] [${e.tnm}] ${this.toShortTime(e.dt)}`, e.text, this.eventColor[e.code], 60);
              this.alerts.unshift( e );
              // console.log( 'array allerts --->', this.alerts )
              if(this.alerts.length>100) this.alerts.pop();

              this.evtLatest[e.code][e.tid] = { dt: e.dt, toasted: true };
            }

          }

          // 비프 사운드
          if(isTankAlarm && cfg.console.sound && tankAlarm.console.sound ) {
            if(['3', '6', '7'].includes(e.code) )
              await beepSound('danger');
            else
              await beepSound('warning');
          }
        }
      }catch(err){
        console.error(err);
      }finally{
        cfg = null;
        tankCfg = null;

      }
    },

    /**
     *
     * @param evtCode
     * @param tid
     * @returns {boolean}
     */
    isAlarmSuppressed( evtCode, tid ){
      const suppressedTank = this.suppressAlarms[evtCode];
      if(!suppressedTank) return false;

      const stopTo = suppressedTank[tid];
      // console.log( 'evtCode, stopTo  --->', evtCode, tid, stopTo );
      if( stopTo ){
        if( moment(stopTo).isAfter() ) {
          // console.debug('[isAlarmSuppressed] checkSuspend alarm suppressed --->', evtCode, tid, this.toLocalTime(stopTo));
          return true;
        } else {
          delete this.suppressAlarms[evtCode][tid];
          return false;
        }
      }else{
        return false;
      }
    },


  },
  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    if(this.socket) this.socket.removeAllListeners('event', this.eventAlarmHandler );
    if(this.suppressInterval ) clearInterval( this.suppressInterval );
  },
}
</script>
